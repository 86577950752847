<template>
  <NuxtI18nLink :to="link" class="variants-link" data-t="variants-link">
    <div class="variants-link-wrapper" :class="type">
      <p data-t="variants-link-title">
        {{ t('variantsLink.title', { count }) }}
      </p>
      <StIcon name="arrow-right" :size="16" />
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
interface Props {
  link?: string
  count: number
  type?: 'gray' | 'ghost'
}
withDefaults(defineProps<Props>(), {
  link: '/',
  type: 'gray',
})
const { t } = useI18n()
</script>

<style scoped>
.variants-link {
  text-decoration: none;
}

.variants-link-wrapper {
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-150);

  font-family: var(--sf-pro-display);
  color: var(--text-primary);

  background: var(--button-gray-default);
  border-radius: var(--border-radius-100);

  &.ghost {
    background-color: var(--button-ghost-default);
    backdrop-filter: blur(4px);

    &:hover {
      background-color: var(--button-ghost-hover);
    }
  }

  &:hover {
    background-color: var(--button-gray-hover);
  }

  p {
    margin: 0;
    font: var(--desktop-text-xss-medium-caps);
    text-transform: uppercase;
  }
}
</style>
